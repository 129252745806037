import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CssBaseline from "@mui/material/CssBaseline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import FontSizeChanger from "react-font-size-changer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import alertify from "alertifyjs";
import { useCookies } from "react-cookie";
import "alertifyjs/build/css/alertify.css";
import BarTop from "../components/BarTop";
import BarBottom from "../components/BarBottom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
let enableNewUI = process.env.REACT_APP_NEWUI_LW;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const backgroundColor = process.env.REACT_APP_BACKGROUND_COLOR;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const hoverColor = process.env.REACT_APP_TEXT_HIGHLIGHT;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <br></br>
      Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by<br></br>
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img
          className="silogo"
          src={siteMedSrc + "si-logo" + ".svg"}
          alt="ServingIntel"
        />
        <br></br>
        <br></br>
      </Link>{" "}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

const theme = createTheme();

export const NotMe = () => {
  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const [newFontSize, setFontSize] = useState(16);
  const increaseFont = () => setFontSize(newFontSize + 2);

  const firstName = sessionStorage.getItem("firstName");
  const roomNumber = sessionStorage.getItem("roomNumber");

  const [pinCode, setPinCode] = useState();
  const [pinCodeConfirm, setPinCodeConfirm] = useState();
  const location = useLocation();
  const [token, setToken] = useState();

  const [cookie, setCookie, removeCookie] = useCookies();

  const customerList = cookie.customerList;
  console.log(customerList);

  const logOut = () => {
    // removeCookie("customerList");
    removeCookie("firstName");
    removeCookie("roomNumber");
    sessionStorage.removeItem("pinCode");
    history.push("/");
  };

  const changeUser = (first, room, last) => {
    //change the user based on selection
    sessionStorage.setItem("firstName", first.firstname);
    sessionStorage.setItem("lastName", last.lastname);
    sessionStorage.setItem("roomNumber", room.room);
    setCookie("firstName", first.firstname, { path: "/" });
    setCookie("roomNumber", room.room, { path: "/" });
    history.push("/LoginCode");
    // console.log(first.firstname);
  };

  const validateAccount = () => {
    const url =
      "https://ecommv2.servingintel.com/customer/save-pin/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      firstName +
      "/" +
      roomNumber +
      "/" +
      pinCode;

    if (!pinCode || !pinCodeConfirm) {
      alertify.alert("Error", "Please Enter your PIN Code", function () {});
      return;
    }
    if (String(pinCode) === String(pinCodeConfirm)) {
      axios.get(url).then((response) => {
        setCookie("firstName", firstName, { path: "/" });
        setCookie("roomNumber", roomNumber, { path: "/" });
        history.push("/LoginCode");
      });
    } else {
      alertify.alert("Error", "PIN code did not match", function () {});
      return;
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {restaurantName}: {tagline}
          </title>
        </Helmet>
      </HelmetProvider>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          justifyContent={enableKiosk === "1" ? "center" : null}
          alignItems={enableKiosk === "1" ? "center" : null}
          sx={{
            fontSize: enableKiosk === "0" ? `${newFontSize}px` : null,
            backgroundImage:
              enableKiosk === "1" ? `url(${siteMedSrc}signbg.png)` : null,
            backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: enableKiosk === "1" ? "cover" : "cover",
            backgroundPosition: enableKiosk === "1" ? "left" : "left",
            left: enableKiosk === "1" ? "0" : "0",
            right: enableKiosk === "1" ? "0" : "0",
            minHeight: "100vh",
            height: enableKiosk === "0" ? "100vh" : null,
          }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={enableKiosk === "1" ? false : 4}
            md={enableKiosk === "1" ? false : 7}
            sx={{
              backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: enableKiosk === "1" ? "cover" : "cover",
              backgroundPosition: enableKiosk === "1" ? "left" : "left",
              left: enableKiosk === "1" ? "0" : "0",
              right: enableKiosk === "1" ? "0" : "0",
              backgroundImage:
                enableKiosk === "0" ? `url(${siteMedSrc}signbg.png)` : null,
            }}
          />
          <Grid
            item
            xs={enableKiosk === "1" ? 10 : 12}
            sm={enableKiosk === "1" ? 10 : 8}
            md={enableKiosk === "1" ? 10 : 5}
            justifyContent="center"
            alignItems="center"
            component={Paper}
            elevation={6}
            square
          >
            <Box
              id="target"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {enableNewUI == 1 ? (
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  className="SL-logo02"
                  alt={`${restaurantName} logo`}
                />
              ) : enableNewUI == 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={siteMedSrc + "logo" + ".png"}
                    width={logoWidth}
                    alt="logo"
                    style={{
                      transform: "scale(2)",
                      paddingTop: ".5rem",
                      paddingBottom: "1rem",
                    }}
                  />
                </div>
              ) : (
                // Add a default case here, for example:
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  className="Default-logo"
                  alt={`${restaurantName} logo`}
                />
              )}
              <br />
              <Typography
                className="content"
                sx={{
                  marginTop: 1,
                  textAlign: "center",
                  lineHeight: 1.1,
                  fontWeight: "bold",
                  fontSize:
                    enableKiosk === "0"
                      ? "1.5rem !important"
                      : "1.75rem !important",
                }}
              >
                Find Your Account
              </Typography>
              <br />
              <Typography
                className="content"
                sx={{
                  marginTop: 2,
                  textAlign: "center",
                  lineHeight: 1.1,
                  fontSize:
                    enableKiosk === "0"
                      ? "1.25rem !important"
                      : "1.5rem !important",
                }}
              >
                Select your <b>Firstname</b> and <b>Room Number</b> from the
                list below
              </Typography>

              <List
                sx={{
                  my: 0,
                  mx: 0,
                  backgroundColor: "#ccc",
                  marginTop: "1rem",
                }}
                component="nav"
                aria-label="folders"
              >
                {customerList.map(({ firstname, room, lastname }) => (
                  // <p key={firstname}>Coffee type {firstname} in a {room} size.</p>
                  <ListItem
                    button
                    onClick={() => {
                      changeUser({ firstname }, { room }, { lastname });
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            fontSize:
                              enableKiosk === "0" ? "1.25rem" : "1.5rem",
                          }}
                        >
                          {firstname.toUpperCase() +
                            " Room: " +
                            room.toUpperCase()}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
                {/* <ListItem button>
                  <ListItemText primary={firstName.toUpperCase() + " Room: " + roomNumber.toUpperCase()} />
                </ListItem> */}
              </List>
              <Box
                className="content"
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: -2 }}
              >
                {/* <TextField
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  name="pinCode"
                  label="Search Name"
                  id="pinCode"
                  inputProps={{ maxLength: 4 }}
                  variant="standard"
                  onKeyUp={(e) => {
                    setPinCode(e.target.value);
                  }}
                /> */}

                {/* <Button
                  size="large"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 5, mb: 1, fontWeight: "600" }}
                  onClick={() => {
                    history.push("LoginCode");
                  }}
                >
                  <Typography className="content" sx={{ fontWeight: "600" }}>
                    CONTINUE
                  </Typography>
                </Button> */}

                <Button
                  id="cancel"
                  size="large"
                  type="cancel"
                  fullWidth
                  // variant="contained"
                  sx={{
                    mt: 5,
                    mb: 2,
                    fontWeight: "600",
                    backgroundColor: buttonColor,
                    "&:hover": {
                      backgroundColor: hoverColor,
                    },
                  }}
                  onClick={() => {
                    logOut();
                  }}
                >
                  <Typography
                    className=""
                    style={{
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                      fontWeight: "600",
                    }}
                  >
                    CANCEL
                  </Typography>
                </Button>
              </Box>
            </Box>
            {enableNewUI === "0" && enableKiosk === "0" ? (
              <Copyright
                id="copyrignt"
                position="centered"
                sx={{ top: "auto", bottom: 0 }}
              />
            ) : null}
            {enableKiosk === "0" ? (
              <FontSizeChanger
                targets={["#target .content"]}
                onChange={(element, newValue, oldValue) => {
                  console.log(element, newValue, oldValue);
                }}
                options={{
                  stepSize: 2,
                  range: 5,
                }}
                customButtons={{
                  up: (
                    <AppBar
                      position="fixed"
                      sx={{
                        width: "90px",
                        boxShadow: 0,
                        top: "auto",
                        bottom: 237.5,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton aria-label="add font size">
                          <Fab
                            color="primary"
                            aria-hidden="true"
                            sx={{ backgroundColor: "#3D5309" }}
                          >
                            <AddCircleOutlineIcon />
                          </Fab>
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  ),
                  down: (
                    <AppBar
                      position="fixed"
                      sx={{
                        width: "90px",
                        boxShadow: 0,
                        top: "auto",
                        bottom: 180,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton>
                          <Fab
                            color="primary"
                            aria-hidden="true"
                            sx={{ backgroundColor: "#765F00" }}
                          >
                            <RemoveCircleOutlineIcon />
                          </Fab>
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  ),
                }}
              />
            ) : null}
          </Grid>
        </Grid>
        {enableNewUI === "1" ? (
          <>
            <BarTop />
            <BarBottom />
          </>
        ) : null}
      </ThemeProvider>
    </>
  );
};
