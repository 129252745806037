import * as React from 'react';
import AppBar from '@mui/material/AppBar';

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

export default function BottomLanding() {
  return (
    <a href="/menu">
      <AppBar position="fixed"
        sx={{ top: 'auto', bottom: 0 }}
        style={{
          background: `linear-gradient(rgba(255,255,255,.05), rgba(0,0,0,.4)), url(${siteMedSrc}bottomlanding.jpg)no-repeat center center`,
        }}
      >
        <div className="bottomlanding padding-top-20">
          <div className="footer-top d-md-block">
            <div className="container">
              <div className="row align-items-center justify-content-between padding-bottom-25">
                <a href="/menu" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: "black" }}>
                  <div className="col-lg-6 col-md-6">
                    <div className="f-logo">
                      <img className="App-logo" src={siteMedSrc + "logo" + ".png"} alt={`${restaurantName} logo`} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="f-title center">
                      <h1 style={{ marginLeft: '20px' }}>
                        Tap anywhere to<span> start</span>
                      </h1>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </AppBar>
    </a>
  );
}
